const hidden = {
  id: 'hidden',
  name: 'Hidden',
  Input: null,
  setInitialContent: () => {
    return {};
  },
  formatContent: () => {
    return {};
  },
  valid: () => true,
  snippet: `{
    "type": "hidden",
    "content": {}
}`
};

export default hidden;
