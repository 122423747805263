const time = {
  id: 'time',
  name: 'Time',
  Input: null,
  setInitialContent: () => {
    return {};
  },
  formatContent: () => {
    return {};
  },
  valid: () => true,
  snippet: `{
    "type": "time",
    "content": {}
}`
};

export default time;
